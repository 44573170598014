.FileShare {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 25%);
    justify-content: center;
    pointer-events: auto;
    align-items: center;
    z-index: 20;
    display: flex;
}

.FileShare .share_info {
    height: 240px;
    width: 250px;
    border-radius: 1em;
    background-color: #1f1f1f;
    border-color: #1d1d1d;
    border-width: 2px;
    border-style: solid;
}

.share_info .spinner_share {
    width: 100%;
    height: calc(100% - 130px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.share_info .create_share {
    display: flex;
    margin: 0px 30px 30px 30px;
    height: calc(100% - 90px);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-style: dashed;
    border-radius: 0.5rem;
    border-color: #888888;
}

.create_share p {
    margin: 0;
    font-weight: bold;
    font-size: 65px;
    font-family: "JetBrains Mono ExtraBold";
    color: #888888;
}

.FileShare .actual_share {
    height: calc(100% - 60px);
}

.share_timer_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 13px;
}

.share_timer_wrapper .share_timer {
    margin: 0;
    font-size: 60px;
    color: #bebebe;
}

.actual_share .share_link_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.share_link_wrapper .share_link {
    margin-left: 7px;
    background-color: #343434;
    padding: 0;
    border: 0;
    border-radius: 0.25rem;
    height: 25px;
    width: 170px;
    color: #24d1c6;
    outline: none;
}

.share_revoke_button_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 9px;
}

.share_revoke_button_wrapper .share_revoke_button {
    margin: 0;
    background-color: #343434;
    border: 0;
    font-size: 14px;
    border-radius: 3px;
    padding: 4px 7px 4px 7px;
    cursor: pointer;
    color: #f63e3e;
}

.share_revoke_button:hover {
    background-color: #e2e2e2;
    color: black;
}

.actual_share .CopyButton {
    display: inline-block;
    margin-left: 1px;
}

.FileShare .share_close {
    color: #FFFFFF;
    display: inline-block;
    float: right;
    margin-top: 13px;
    margin-right: 13px;
    border-radius: 1000px;
    background: #d75f70;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.FileShare .share_close:hover {
    opacity:0.75;
}

.share_close p {
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    margin-top: 7.8px;
    font-size: 14px;
    margin-bottom: 0;
    height: 100%;
}

.share_info .share_headline {
    display: inline-flex;
    justify-content: center;
    margin-top: 13px;
    width: calc(100% - 83px);
    margin-right: 8px;
    margin-left: 32px;
}

.share_headline p {
    font-size: 19px;
    font-weight: normal;
    margin-top: 3.8px;
    color: #24d1c6;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: center;
}
