.special-btn {
	background-color: #363636;
	color: white;
	padding: 4px 4px 4px 4px;
	font-size: 15px;
	border-radius: 5px;
	font-family: 'Ubuntu', sans-serif;
	margin-right: 5px;
	border-style: solid;
	border-color: #66FCF1;
	border-width: 2px;
	cursor: pointer;
}

.special-btn:hover {
	animation: special-btnHover 0.3s forwards ease;
}

.special-btn:hover:disabled {
	animation: none !important;
}

.special-btn:disabled {
	border-color: #363636;
	background-color: #6D6D6D;
	color: #FFFFFF;
}

@keyframes special-btnHover {
	0% {
		background-color: #363636;
	}
	100% {
		background-color: #4e4e4e;
	}
}

.btn:hover {
	animation: btnHover 0.2s forwards ease;
}

@keyframes btnHover {
	0% {
		box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
		background-color: rgba(255, 255, 255, 0);
	}
	100% {
		box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.18);
		background-color: rgba(255, 255, 255, 0.18);
	}
}