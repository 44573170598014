.Files {
	top: 30px;
	position: relative;
	margin-left: 3px;
	padding-bottom: 35px;
	width: 95%;
	float: left;
	height: auto;
}

.file_input_wrapper {
	margin-bottom: 10px;
}

.file_input_wrapper input {
	display: none;
}

.file_input_wrapper .file_input_display {
	color: white;
	display: inline;
}
