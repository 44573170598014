.File {
	border-radius: 4px;
	width: 100%;
	height: 50px;
	background-color: #2c2c2c;
	margin-top: 8px;
}

.File .file_info_div {
	position: relative;
	margin-left: 20px;
	display: inline-block;
	color: white;
	height: 100%;
	padding: 0px 0px 0px 4px;
	border-radius: 2px;
	width: calc(100% - 250px);
}

.file_info_div .filename_display {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: inline-block;
	width: 100%;
}

.file_button_div .filesize_display {
	color: #76c576;
	float: left;
	padding-right: 15px;
	display: inline;
}

.File .file_button_div {
	position: relative;
	float: right;
	margin-right: 20px;
	display: inline;
}

.download_img {
	width: 28px;
	display: block;
	margin-top: 11px;
	margin-right: 13px;
	float: left;
	cursor: pointer;
	border-radius: 1.5rem;
}

.delete_img {
	width: 28px;
	display: block;
	margin-top: 11px;
	float: left;
	cursor: pointer;
	border-radius: 1.5rem;
}

.share_img {
	width: 28px;
	display: block;
	margin-top: 11px;
	float: left;
	cursor: pointer;
	margin-right: 15px;
	border-radius: 1.5rem;
}

