.Dashboard {
	overflow-y: auto;
	overflow-x: hidden;
	margin-left: 10px;
	bottom: 50px;
	left: 0px;
	right: 0px;
	top: 0px;
	position: relative;
	width: 100%;
	height: 100%;
	animation: fade-in 0.2s ease-out;
}

.Dashboard h1 {
	color: #66FCF1;
	font-size: 50px;
	text-shadow: 1px 1px rgba(0, 0, 0, 0.18);
	position: relative;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
