#root {
    height: 100%;
}

html {
    background-color: #383838;
    position: absolute;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}