.Auth {
    background-color: #ececec;
    width: 400px;
    height: 380px;
    margin: auto;
    border-radius: 1.5em;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
    padding-top: 40px;
    position: relative;
}

.Auth .info {
    padding-top: 5px;
    color: #24d1c6;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 23px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.14);
    padding-bottom: 27px;
}

.Auth input {
    width: 76%;
    color: rgb(38, 50, 56);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background: rgba(136, 126, 126, 0.10);
    padding: 10px 30px;
    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-left: 46px;
    text-align: center;
    margin-bottom: 23px;
    font-family: 'Ubuntu', sans-serif;
}

.Auth input:focus {
    border: 2px solid rgba(0, 0, 0, 0.18) !important;
}

.Auth .response {
    color: red;
    opacity: 0;
}

.fade {
    animation-name: response-fade !important;
    animation-duration: 1.8s !important;
}

.button_container {
    display: flex;
    justify-content: center;
}

@keyframes response-fade {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.slide-left {
    transform: translateX(-80vw);
    transition: transform 0.3s ease-in;
    /*animation: slide-left-login 2s ease-out !important;*/
}

@media (max-width: 600px) {
    .Auth {
        border-radius: 0px;
    }
}
