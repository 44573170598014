.Nav button {
	border-radius: 5px;
	background-color: #363636;
	color: #e2e2e2;
	font-size: 20px;
	display: inline-block;
	padding: 3px 3px 3px 3px;
	letter-spacing: 1px;
	margin-left: 4px;
	border: 2px solid transparent;
}

.Nav button.active {
	border: 2px solid #66FCF1 !important;
}

.Nav button:hover {
	border: 2px solid #66FCF1 !important;
	cursor: pointer;
}

.Nav .dropdown_container {
	display: inline-block;
	padding: 0;
	margin: 0;
	z-index: 9;
	width: max-content;
}