.Spinner {
	width: 100%;
	height: 100%;
	padding: 5px 40px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Spinner .loader {
	position: relative;
	margin: 0 auto;
	width: 100%;
	stroke: #66FCF1;
	content: '';
	display: block;
	padding-top: 55%;
}

.Spinner .loader .circular {
	animation: rotate 2s linear infinite;
	height: 100%;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.Spinner .loader .circular .path {
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	animation: dash 1.5s ease-in-out infinite;
	stroke-linecap: round;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}
