.SpecialButton {
	padding: 0 0 0 0;
	border-width: 0px;
}

.special-btn_content_wrapper {
	border-style: solid;
	border-width: 2px;
	padding: 4px 4px 4px 4px;
	border-radius: 5px;
	border-color: #66FCF1;
}

.SpecialButton > .special-btn_animator_wrapper {
	position: absolute;
}

.special-btn_animator {
	height: 100%;
	background: rgba(255, 255, 255, 0%);
	position: relative;
}

.special-btn_animator.loading {
	background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 100%);
	animation: slide-loading ease infinite 1s !important;
	border-radius: 5px;
}

@keyframes slide-loading {
	0% {
		opacity: 0;
		width: 0%;
	}
	10% {
		opacity: 1;
	}
	60% {
		opacity: 1;
		width: 100%;
	}
	100% {
		width: 100%;
		opacity: 0;
	}
}

.SpecialButton:hover.disabled {
	animation: none !important;
}

.SpecialButton.disabled {
	opacity: 0.65;
}

.special-btn_content_wrapper.disabled {
	opacity: 0.65;
}
