.Account {
	background-color: #2c2c2c;
	top: 30px;
	position: relative;
	margin-left: 3px;
	width: 95%;
	float: left;
	bottom: 50px;
	white-space: nowrap;
	padding: 10px 10px 10px 10px;
	color: white;
	border-radius: 5px;
}

.Account div {
	display: inline-block;
}

.Account button {
	display: inline-block;
}
