.LoginButton {
  border-radius: 5em;
  background: rgba(0, 0, 0, 0.65);
  border: 2px solid #66FCF1;
  width: 120px;
  height: 32px;
  margin-top: 15px;
  font-size: 13px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
  text-decoration: none;
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  font-family: 'Ubuntu', sans-serif;
  color: #fff;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginButton p {
  margin: 0 0 0 0;
  padding: 5px 20px;
  width: 100%;
}

.LoginButton:hover {
  animation: special-btnHover 0.3s forwards ease;
}

@keyframes special-btnHover {
  0% {
    background-color: #363636;
  }
  100% {
    background-color: #4e4e4e;
  }
}

