.Reset {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
}

.Reset .Auth {
	height: 360px;
}
