.FileDropWindow {
	height: 0%;
	width: 0%;
	position: fixed;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 25%);
	justify-content: center;
	pointer-events: auto;
	align-items: center;
	z-index: 20;
	display: flex;
	opacity: 100%;
}

.FileDropWindow .drop_target {
	border-radius: 1em;
	position: relative;
	animation: 0.4s scaleXY forwards;
	background-color: #66fcf1;
	display: flex;
	pointer-events: none;
	align-items: center;
	justify-content: center;
}

.drop_target_style {
	border: #1f1f1f;
	border-width: 3px;
	border-radius: 0.5em;
	border-style: dashed;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 0.1em;
	height: 85%;
}

.drop_target_headline {
	text-align: center;
	display: flex;
	margin-top: 23px;
	animation: 0.25s fade-a forwards;
}

.drop_target_info {
	text-align: center;
	display: flex;
	animation: 0.25s fade-a forwards;
	width: 80%;
	flex-grow: 2;
}

@keyframes fade-a {
	0% {
		color: rgba(0, 0, 0, 0%);
	}
	80% {
		color: rgba(0, 0, 0, 0%);
	}
	100% {
		color: rgba(0, 0, 0, 100%);
	}
}

@keyframes scaleXY {
	0% {
		width: 0em;
		height: 0em;
		border-radius: 0.5em;
	}
	80% {
		border-radius: 1em;
		width: 20em;
		height: 10em;
	}
	100% {
		border-radius: 1em;
		width: 20em;
		height: 10em;
	}
}
