.File.onupload {
	border-radius: 4px;
	width: 100%;
	height: 50px;
	background-color: #2c2c2c;
	margin-top: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.file_info_div.onupload {
	display: flex;
	align-items: center;
	width: calc(100% - 90px);
	overflow: hidden;
	margin-top: -1px;
}

.filename_display.onupload {
	width: 60%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.process_wrapper {
	display: flex;
	align-items: center;
	width: 100%;
}

.process_container {
	position: relative;
	height: 10px;
	margin-left: calc(40% - 58px);
	display: flex;
	align-items: center;
	background: rgb(31, 31, 31);
	width: 60%;
	box-sizing: border-box; /* Add this line */
	border-radius: 0 0.4rem 0.4rem 0;
}

.process {
	height: 100%;
	width: 0%;
	border-radius: 0.4rem;
	background: #24d1c6;
	position: relative;
	transition: width 0.4s ease;
}

.process_percent {
	display: inline-block;
	margin-left: 5px;
	color: white;
	vertical-align: top;
}

.File.onupload .file_button_div {
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 20px;
}

.delete_img.onupload {
	width: 28px;
	display: block;
	margin-top: 0px;
	cursor: pointer;
	border-radius: 1.5rem;
}

.pulse_success {
	animation-name: pulse;
	animation-duration: 0.7s;
}

@keyframes pulse {
	0% {
		background-color: #2c2c2c;
	}
	20% {
		background-color: #47cf3a;
	}
	100% {
		background-color: #2c2c2c;
	}
}
